import { FrameQueue } from './frame-queue.js';

export class Chart {
    constructor(app, $elem, opt) {
        this.$elem = $elem;
        this.$chart = $('<div class="chart-render">');
        this.$elem.append(this.$chart);

        this.opt = this.getOptions(opt);
        this.ids = this.getDataIds();

        this.render();

        this.initLegend(() => app.changed());
        this.initMenu();
        this.initDownload();

        if (this.opt.shadowserver && this.opt.shadowserver.resize_to_fit) {
            // Billboard.js already handles resizing to fit the
            // container element's width, but on the dashboard we
            // need to use custom resizing to fit the container's
            // height, allowing space for our legend.

            this.resize();

            util.handleResize(this.$elem.get(0), () => {
                this.resize();
            });
        }
    }

    render() {
        this.handleHeight();
        this.obj = bb.generate(this.opt);
    }

    resize() {
        this.$chart.hide();
        let h = this.$elem.height();
        let w = this.$elem.width();
        if (this.$legend) {
            h -= this.$legend.height();
        }
        this.$chart.show();
        this.obj.resize({height: h, width: w});
    }

    handleHeight() {
        // If the options specify a height we need to be wary of tall charts
        // triggering a scrollbar. If that happens the available width might
        // be reduced after rendering and the chart might overflow. Sizing the
        // container before rendering the chart should help to avoid this.
        let h = this.opt.size?.height;
        if (h) this.$chart.css('min-height', h);
    }

    allowDownloadAsPng() {
        if (this.opt.shadowserver
        &&  typeof(this.opt.shadowserver.allow_toggle_download_as_png) !== 'undefined') {
            return this.opt.shadowserver.allow_toggle_download_as_png;
        }

        return true;
    }

    allowToggleHighContrast() {
        if (this.opt.shadowserver
        &&  typeof(this.opt.shadowserver.allow_toggle_high_contrast) !== 'undefined') {
            return this.opt.shadowserver.allow_toggle_high_contrast;
        }

        return true;
    }

    allowToggleVisible() {
        if (this.opt.shadowserver
        &&  typeof(this.opt.shadowserver.allow_toggle_visible) !== 'undefined') {
            return this.opt.shadowserver.allow_toggle_visible;
        }

        return this.ids.length > 1 && this.$legend;
    }

    allowToggleStacking() {
        if (this.opt.shadowserver
        &&  typeof(this.opt.shadowserver.allow_toggle_stacking) !== 'undefined') {
            return this.opt.shadowserver.allow_toggle_stacking;
        }

        var dataType = this.opt.data.type;
        var dataTypeSupported = ['bar', 'area', 'area-spline', 'line', 'spline'];

        var axisType = this.opt.axis && this.opt.axis.x && this.opt.axis.x.type;
        var axisTypeSupported = ['timeseries', 'category'];

        return this.ids.length > 1
            && dataTypeSupported.indexOf(dataType) != -1
            && axisTypeSupported.indexOf(axisType) != -1;
    }

    initDownload() {
        if (!this.allowDownloadAsPng()) {
            return;
        }

        const $downloadDiv = $('.stats-download');
        const $downloadBtn = $downloadDiv.find('.download-as-png a');

        $downloadDiv.show();

        $downloadBtn.unbind('click').click(e => {
            e.preventDefault();

            if ($downloadBtn.hasClass('btn-disabled')) return;
            const $generating = util.loadingIndicatorBtn(gettext("Generating"));

            var queue = new FrameQueue();

            queue.add(() => {
                $downloadBtn.before($generating);
                $downloadBtn.hide();
            });

            queue.add(() => {
                this.downloadAsPng(() => {
                    $generating.remove();
                    $downloadBtn.show();
                });
            });

            queue.run();
        });
    }

    initMenu() {
        if (this.opt.interaction && this.opt.interaction.enabled === false) {
            return;
        }
        var $menu = $('<div class="chart-menu menu-wrapper menu-wrapper-with-arrow"><div class="menu-options"></div></div>');
        var $list = $('<ul></ul>');

        if (this.allowToggleHighContrast()) {
            $list.append(this.initMenuItem(
                gettext("Toggle high contrast"),
                this.toggleHighContrast.bind(this),
            ));
        }
        if (this.allowToggleVisible()) {
            $list.append(this.initMenuItem(
                gettext("Toggle visibility"),
                this.toggleVisible.bind(this)
            ));
        }
        if (this.allowToggleStacking()) {
            $list.append(this.initMenuItem(
                gettext("Toggle stacking"),
                this.toggleStacking.bind(this)
            ));
        }

        if ($list.children().length > 0) {
            $menu.find('.menu-options').append($list);
            $menu.appendTo(this.$elem);
            this.$elem.hover(function(){
                $menu.show();
            }, function(){
                $menu.hide();
            });
        }
    }

    initMenuItem(text, fn) {
        var $a = $('<li><a href="#">' + text + '</a></li>');

        $a.click(function(e){
            e.preventDefault();
            if (fn.length) {
                // If this function accepts a finishedLoading callback
                // parameter we should show & hide a loading indicator.
                if ($a.hasClass('btn-loading') == false) {
                    $a.toggleClass('btn-loading', true);
                    fn(function(){
                        $a.toggleClass('btn-loading', false);
                    });
                }
            } else {
                // Otherwise just call the function.
                fn();
            }
        });

        return $a;
    }

    initLegend(done) {
        if (this.opt.shadowserver
        &&  this.opt.shadowserver.legend
        &&  this.opt.shadowserver.legend.show !== true) {
            return;
        }

        if (this.ids.length == 0) {
            return;
        }

        const limit = (this.opt.shadowserver
                    && this.opt.shadowserver.legend
                    && this.opt.shadowserver.legend.limit);

        this.$legend = $('<div class="chart-legend">');
        this.$elem.append(this.$legend);

        // See: https://naver.github.io/billboard.js/demo/#Legend.CustomLegend

        const self = this;
        const divs = d3.select(this.$legend.get(0))
            .selectAll('div')
            .data(this.ids)
            .enter()
                .append('div')
                .attr('class', 'chart-legend-item')
                .attr('id', id => id)
                .html(id => `<i></i>${id}`)
                .each(function(id){
                    $(this).find('i').css('color', self.obj.color(id));
                });

        // Only show the first N items if there are too many to display.

        if (limit && divs.size() > limit) {
            const other = divs.filter((d, i) => i >= limit);
            const otherSize = other.size();

            const showLabel = interpolate(ngettext("Show %s other…", "Show %s others…", otherSize), [otherSize]);
            const hideLabel = interpolate(ngettext("Hide %s other…", "Hide %s others…", otherSize), [otherSize]);

            const $link = $(`<a href="#" class="dim">${showLabel}</a>`);
            const $span = $('<span class="dim" style="white-space: nowrap;">&ndash;&nbsp; </span>').append($link);

            other.style('display', 'none');

            $link.click(() => {
                if (other.style('display') == 'none') {
                    $link.text(hideLabel);
                    other.style('display', 'inline-block');
                } else {
                    $link.text(showLabel);
                    other.style('display', 'none');
                }
            });

            this.$legend.append($span);
        }

        if (!this.opt.interaction || this.opt.interaction.enabled) {
            divs
                .attr('class', 'chart-legend-item chart-legend-item-interactive')
                .on('mouseover', function(event, id){
                    self.obj.focus(id);
                    $(this).addClass('on-hover');
                })
                .on('mouseout', function(event, id){
                    self.obj.revert();
                    $(this).removeClass('on-hover');
                })
                .on('click', function(event, id){
                    self.obj.toggle(id);
                    $(this).toggleClass('off');
                });
        }

        done();
    }

    toggleHighContrast() {
        this.$elem.toggleClass('chart-high-contrast');
    }

    toggleVisible() {
        var toggle = !!this.obj.data.shown().length;
        var $items = this.$legend.find('.chart-legend-item');
        if (toggle) {
            this.withoutAnimation(() => this.obj.hide(this.ids));
            $items.addClass('off');
        } else {
            this.withoutAnimation(() => this.obj.show(this.ids));
            $items.removeClass('off');
        }
    }

    toggleStacking() {
        var toggle = !!this.obj.groups().length;
        if (toggle) {
            this.obj.groups([]);
        } else {
            this.obj.groups([this.ids]);
        }
    }

    withoutAnimation(fn) {
        const newValue = 0;
        const oldValue = this.obj.config('transition.duration');
        this.obj.config('transition.duration', newValue);
        fn();
        this.obj.config('transition.duration', oldValue);
    }

    downloadAsPngNoLegend(filename, finishedLoading) {
        util.downloadAsPng(this.$chart.get(0), filename, finishedLoading, true, true, 20);
    }

    downloadAsPngCombined(filename, finishedLoading) {
        // We're now rendering a custom legend outside the SVG by default
        // (see Chart.initLegend). That means that we need to render the
        // SVG and the legend's DOM as two separate images and then merge
        // them onto one canvas to get our final image URL.

        let dim = {'scale': window.devicePixelRatio};

        dim.w1 = this.$chart.width();
        dim.h1 = this.$chart.height();

        dim.w2 = this.$legend.outerWidth();
        dim.h2 = this.$legend.outerHeight();

        util.download(filename, (finishedRendering) => {
            util.renderToCanvas(this.$chart.get(0)).then(canvasA => {
                util.renderToCanvas(this.$legend.get(0)).then(canvasB => {
                    var cvs = document.createElement('canvas');
                    var ctx = cvs.getContext('2d');
                    var url;

                    cvs.width  = Math.floor(dim.scale * (dim.w1));
                    cvs.height = Math.floor(dim.scale * (dim.h1 + dim.h2));

                    // Scale for high DPI screens:
                    ctx.scale(dim.scale, dim.scale);
                    // Draw a white background:
                    ctx.fillStyle = '#ffffff';
                    ctx.fillRect(0, 0, dim.w1, dim.h1 + dim.h2);
                    // Draw the chart:
                    ctx.drawImage(canvasA, 0, 0, dim.w1, dim.h1);
                    // Draw the legend:
                    ctx.drawImage(canvasB, 0, dim.h1, dim.w2, dim.h2);

                    // Get URL and trigger download:
                    cvs = util.cropWhitespace(cvs);
                    cvs = util.addPadding(cvs, 20);
                    util.addWatermark(cvs, true, cvs => {
                        url = cvs.toDataURL().replace('data:image/png', 'data:application/octet-stream');
                        finishedRendering(url);
                        finishedLoading();
                    });
                });
            });
        });
    }

    downloadAsPng(finishedLoading) {
        var filename = 'chart.png';
        var noLegend = (this.opt.shadowserver
                     && this.opt.shadowserver.legend
                     && this.opt.shadowserver.legend.show !== true);

        // hide tooltip before rendering, otherwise it can appear in PNG output
        // (mostly a problem for touch devices where the tooltip stays open on tap
        // instead of only showing on hover).
        this.obj.tooltip.hide();
        // ensure none of the chart appears faded out - this is visible when exporting
        // png of pie chart after clicking on a segment.
        this.obj.revert();

        this.makeClipPathsRelative();
        var finishedLoadingWrapper = () => {
            finishedLoading();
            this.makeClipPathsRelative(true);
        }

        if (noLegend) {
            this.downloadAsPngNoLegend(filename, finishedLoadingWrapper);
        } else {
            this.downloadAsPngCombined(filename, finishedLoadingWrapper);
        }
    }

    makeClipPathsRelative(reverse = false) {
        // Billboard.js (formerly c3.js) uses absolute clip-path URLs,
        // which html2canvas doesn't render correctly. As a fix we can
        // replace absolute refs with relative refs before rendering.
        //
        // - https://github.com/c3js/c3/issues/1046
        // - https://github.com/niklasvh/html2canvas/issues/2016#issuecomment-796818774

        for (let node of this.$chart.find('[clip-path]')) {
            let $clipped = $(node);
            let clipPath = $clipped.attr('clip-path');

            let documentUrl = document.URL.split('#')[0];
            let clipPathUrl = clipPath.replace(/^url\((.*?)\)$/, '$1');

            if (reverse) {
                clipPathUrl = clipPathUrl.replace(/^(?=#)/, documentUrl);
            } else {
                clipPathUrl = clipPathUrl.replace(documentUrl, '');
            }

            $clipped.attr('clip-path', `url(${clipPathUrl})`);
        }
    }

    getOptions(opt) {
        opt = opt || this.$elem.data('bb');
        opt.bindto = this.$chart.get(0);

        try {
            opt.data.type = bbDataTypes[opt.data.type]();
        } catch (e) {
            throw new Error("Billboard.js chart type has not been imported: " + opt.data.type);
        }

        opt = this.setOptionsForTicks(opt);

        this.formatterReplace(opt, 'axis.y.tick.format');
        this.formatterReplace(opt, 'axis.y2.tick.format');
        this.formatterReplace(opt, 'tooltip.format.title');
        this.formatterReplace(opt, 'tooltip.format.value');

        if (opt.shadowserver) {
            opt = this.setOptionsForLegend(opt);
            opt = this.setOptionsForTooltip(opt);
        }

        return opt;
    }

    getDataIds() {
        return this.opt.data.columns.reduce((arr, [id, values]) => {
            return (id == 'x' || id === null) ? arr : arr.concat(id);
        }, []);
    }

    setOptionsForTicks(opt) {
        const chartW = this.$chart.width();

        // We've set an x-axis tick width in our bar chart helper to try
        // to avoid wrapping labels, but if we're rendering at a smaller
        // width this is likely to use up more space for the labels than
        // for the chart data, so use a smaller value instead.

        if (opt.axis && opt.axis.x && opt.axis.x.tick && opt.axis.x.tick.width) {
            if (opt.axis.x.tick.width > (chartW * 0.33)) {
                opt.axis.x.tick.width = (chartW * 0.33);
            }
        }

        // For bar charts and time-series charts rendered at a smaller width
        // we can use rotated labels to avoid overlapping text.

        if (opt.data && opt.data.type == 'bar') {
            // Rough px-width of 'NNNU' label.
            const labelW = 30;
            // Maximum ticks expected on the y axis.
            const nTicks = opt?.axis?.y?.tick?.culling?.max ?? 10;
            // See tick.width above for why this uses 2/3s of chartW.
            if ((labelW * nTicks) > (chartW * 0.66)) {
                opt.axis.y.tick.rotate = -45;
                opt.axis.y.tick.culling = opt.axis.y.tick.culling || {};
                opt.axis.y.tick.culling.max = nTicks;

                opt.axis.y2.tick.rotate = -45;
                opt.axis.y2.tick.culling = opt.axis.y2.tick.culling || {};
                opt.axis.y2.tick.culling.max = nTicks;
            }
        }

        if (opt.axis && opt.axis.x && opt.axis.x.type == 'timeseries') {
            // Rough px-width of 'YYYY-MM-DD' label.
            const labelW = 60;
            // Maximum ticks expected on the x axis.
            const nTicks = opt?.axis?.x?.tick?.culling?.max ?? 10;
            if ((labelW * nTicks) > chartW) {
                opt.axis.x.tick.rotate = -45;
                opt.axis.x.tick.culling = opt.axis.x.tick.culling || {};
                opt.axis.x.tick.culling.lines = false;
            } else {
                // If we aren't using rotation then we need to
                // try not to truncate right-most x-axis label.
                opt.axis.x.padding = opt.axis.x.padding || {};
                opt.axis.x.padding.right = 25
                opt.axis.x.padding.unit = 'px'
            }
        }

        return opt;
    }

    setOptionsForLegend(opt) {
        // We want to disable the billboard.js legend and replace it with our
        // own custom version outside the chart area, so that the chart isn't
        // squashed upwards if the legend is too large (see Chart.initLegend).
        //
        // But if the legend was explicitly disabled in the options we also
        // need to take note of that so we don't go ahead and display our
        // custom version anyway.

        opt.legend = opt.legend || {};
        opt.shadowserver.legend = opt.shadowserver.legend || {};

        if (opt.legend.show === false || opt.legend.hide === true) {
            opt.shadowserver.legend.show = false;
        } else {
            opt.shadowserver.legend.show = true;
        }

        opt.legend.show = false;

        return opt;
    }

    setOptionsForTooltip(opt) {
        opt.tooltip = opt.tooltip || {};
        opt.tooltip.contents = this.getTooltipContent.bind(this);
        return opt;
    }

    getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color) {
        var s = '';
        if (this.opt.shadowserver.tooltip && this.opt.shadowserver.tooltip.sort_by_value) {
            d.sort(function(a,b){ return b.value - a.value; });
        }
        if (this.opt.shadowserver.tooltip && this.opt.shadowserver.tooltip.hide_zeros) {
            d = d.filter(function(a){ return !!a.value; });
        }
        // If all values are known to be percentages of some fixed
        // value for the whole chart.
        if (this.opt.shadowserver.tooltip.percentage_of) {
            var pcOf = this.opt.shadowserver.tooltip.percentage_of;
            defaultValueFormat = function(a) {
                var pc = util.number.round(a / pcOf * 100, 1);
                return a + ' (' + pc + ' %)';
            }
        }
        if (d.length) {
            s = this.obj.internal.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
            s = '<div class="bb-tooltip-wrapper">' + s + '</div>';
        }
        if (this.opt.shadowserver.tooltip && this.opt.shadowserver.tooltip.show_total) {
            if (d.length > 1 && this.opt.data.groups) { // Only if it actually makes sense for the graph.
                var valueFormat = this.obj.internal.config.tooltip_format_value || defaultValueFormat;
                var total = valueFormat(d.reduce(function(a, b){ return a + b.value; }, 0));
                s = s.replace(/<th colspan=['"]2['"]>/, '<th>');
                s = s.replace('</th></tr>', '</th><th class="total">' + total + '</th></tr>');
            }
        }
        if (this.opt.shadowserver.tooltip && this.opt.shadowserver.tooltip.limit) {
            var limit = this.opt.shadowserver.tooltip.limit;
            var index = limit + 1; // Skip the header row.
            var $elem = $(s);
            var $rows = $elem.find('tr').slice(index).remove();
            var other = $rows.length;
            if (other) {
                $elem.find('tbody').append(`<tr><td colspan="2" style="color: var(--shade-mid); padding-left: calc(5px + 6px + 0.5em);">And ${other} other${(other==1) ? '':'s'}&hellip;</td></tr>`)
            }
            s = $elem.prop('outerHTML');
        }
        return s;
    }

    formatterReplace(opt, objPath) {
        // The billboard.js chart data is returned as JSON from our Django
        // views, which means we can't directly define any formatter functions.
        // As an alternative we can set an identifier string in the JSON and
        // swap it out for the appropriate function just before initialising
        // the chart. But in order to do that we need to be careful about how
        // we access deeply nested object properties to avoid triggering a
        // TypeError:

        var objPathParts = objPath.split('.');
        var len = objPathParts.length;
        var obj = opt;
        for (var i = 0; i < len; i++) {
            var key = objPathParts[i];
            var val = obj[key];
            if (val == undefined) {
                break;
            }
            if (i != (len - 1)) {
                obj = val;
            } else {
                if (val in this.formatters) {
                    obj[key] = this.formatters[val].bind(this.formatters);
                } else {
                    throw new Error('Chart formatter for ' + objPath + ' not found: "' + val + '"');
                }
            }
        }
    }

    formatters = {
        'commas': function(value) {
            value = this.integers(value);
            value = value && util.number.commas(value);
            return value;
        },
        'integers': function(value) {
            /* Hide any non-integer ticks */
            return (value % 1) ? null : value;
        },
        'filesize': function(value, places) {
            return util.number.units(value, places, {scale: 1024, space: ' ', units: ['', 'KB', 'MB', 'GB', 'TB', 'PB']});
        },
        'bitrate': function(value, places) {
            return util.number.units(value, places, {scale: 1000, space: ' ', units: ['bps', 'kbps', 'Mbps', 'Gbps', 'Tbps']});
        },
        'suffix': function(value, units) {
            if (value) {
                return value + units;
            }
        },
        'duration': function(value) {
            var h = Math.floor(value / (60 * 60));
            var m = Math.floor(value / 60) % 60;
            var s = value % 60;
            return util.pad(h, 2) + ':' + util.pad(m, 2) + ':' + util.pad(s, 2);
        },
        'Ymd_HM': function(value) {
            return d3.timeFormat('%Y-%m-%d %H:%M')(value);
        },
        'Ymd_HMS': function(value) {
            return d3.timeFormat('%Y-%m-%d %H:%M:%S')(value);
        },
        'log': function(value) {
            value = Math.pow(10, value).toFixed(0) - 1;
            value = this.commas(value); // Since it's likely to be large.
            return value;
        },
        'filesize_1dp': function(value) { return this.filesize(value, 1); },
        'filesize_2dp': function(value) { return this.filesize(value, 2); },
        'filesize_3dp': function(value) { return this.filesize(value, 3); },
        'bitrate_1dp': function(value) { return this.bitrate(value, 1); },
        'bitrate_2dp': function(value) { return this.bitrate(value, 2); },
        'bitrate_3dp': function(value) { return this.bitrate(value, 3); },
        'units': function(value) { return util.number.units(value, 1, {fixed: false}); },
        'units_kilowatts':  function(value) { return this.suffix(value, ' kW'); },
        'units_fahrenheit': function(value) { return this.suffix(value, '°F'); },
        'units_percentage': function(value) { return this.suffix(value, '%'); }
    }
}
