function setConfigFromDataAttrs($elem, prefix, config) {
    for (var key in config) {
        if (config.hasOwnProperty(key)) {
            var dataKey = prefix + '-' + key;
            if ($elem.data(dataKey) !== undefined) {
                config[key] = $elem.data(dataKey);
            }
        }
    }
}

export function initChosenSelect($select) {
    if (inited($select, 'chosen')) {
        return;
    }

    // Django adds a label with hyphens for the blank option by default but
    // Chosen gives us a nicer prompt if the option label is an empty string.
    var $option = $select.find('option').eq(0);
    if ($option.val() == '' && $option.text() == '---------') {
        $option.text('');
    }

    var config = {
        search_contains: true,
        allow_single_deselect: true,
        placeholder_text_single: gettext("Select an option..."),
        placeholder_text_multiple: gettext("Select one or more options...")
    };

    if ($select.css('direction') == 'rtl') {
        config.rtl = true;
    }

    if ($select.closest('.field').length) {
        config.width = '100%';
    }

    setConfigFromDataAttrs($select, 'chosen', config);

    $select.chosen(config);
}
