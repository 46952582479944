export function hasTextOverflow(elem) {
    // If the text overflowed by more than a pixel then a straightforward
    // test will be enough. But if it didn't overflow or only overflowed
    // by a fraction of a pixel then clientWidth and scrollWidth will be
    // identical. So to figure out if text-overflow was triggered we'll
    // need to find a different way to measure the content width.
    let contentWidth = elem.scrollWidth;
    if (contentWidth == elem.clientWidth) {
        contentWidth = parseFloat(getComputedValue(elem, 'padding-left'), 10)
                     + parseFloat(getComputedValue(elem, 'padding-right'), 10)
                     + getCanvasTextWidth(elem);
    }
    return contentWidth > elem.clientWidth;
}

function getComputedValue(elem, prop, defaultValue = null) {
    let style = window.getComputedStyle(elem, null);
    let value = style.getPropertyValue(prop) || defaultValue;
    return value;
}

function getCanvasTextWidth(elem) {
    // Reuse canvas object for better performance.
    const canvas = getCanvasTextWidth.canvas || (getCanvasTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = getCanvasFont(elem);
    context.letterSpacing = getComputedValue(elem, 'letter-spacing');
    return context.measureText(elem.innerText).width;
}

function getCanvasFont(elem = document.body) {
    const fontWeight = getComputedValue(elem, 'font-weight', 'normal');
    const fontSize = getComputedValue(elem, 'font-size', '16px');
    const fontFamily = getComputedValue(elem, 'font-family', 'Times New Roman');
    return `${fontWeight} ${fontSize} ${fontFamily}`;
}
