export class FrameQueue {
    // Just a little helper to perform a sequence of events over
    // multiple animation frames. This can be useful when making
    // an update to the DOM before performing a complex operation.
    // Without this any changes to the DOM might not be rendered
    // until after the operation has finished.

    items = [];

    add(fn) {
        this.items.push(fn);
    }

    run() {
        return new Promise((resolve, reject) => {
            const run = () => {
                var fn = this.items.shift();
                if (fn) {
                    window.requestAnimationFrame(() => {
                        fn();
                        run();
                    });
                } else {
                    resolve();
                }
            };
            run();
        });
    }
}


