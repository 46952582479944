import * as chroma from 'chroma-js';

export function colorRange(seeds, steps) {
    // const colorRange = ['#00429d', '#2e59a8', '#4771b2', '#5d8abd', '#73a2c6', '#8abccf', '#a5d5d8', '#c5eddf', '#ffffe0'];
    // const colorRange = ['#3a0c00', '#603b00', '#916600', '#c79401', '#ffc41e'];
    // const colorRange = ['#222223', '#554629', '#8b6d2c', '#c3972a', '#ffc41e'];
    // const colorRange = ['#222223', '#50342f', '#7c4739', '#a65f40', '#cb7b41', '#ea9d3b', '#ffc41e'];

    // This palette was generated using the chroma.js palette helper tool:
    //     https://gka.github.io/chroma.js/
    //     https://gka.github.io/palettes/#/7|s|222223,d95c55,ffc41e|ffffe0,ff005e,93003a|1|1
    //
    // The palette was generated using these initial colors:
    //     #ffc41e, #d95c55, #222223
    //
    // The start and end of the range are the black and yellow used on the public shadowserver site.
    // The mid-point of the range was color-picked from our world map using the color brewer palette
    // but note that we use opacity for these colors, so the color isn't actually in the palette.
    //
    // The palette can also be generated in code using chroma.js:
    //     chroma.bezier(['#ffc41e', '#d95c55', '#222223']).scale().correctLightness().colors(7);

    // const colorRange = ['#a50026','#d73027','#f46d43','#fdae61','#fee090','#ffffbf','#e0f3f8','#abd9e9','#74add1','#4575b4','#313695'].reverse();
    // const colorRange = ['#ffc41e', '#ea9d3b', '#cb7b41', '#a65f40', '#7c4739', '#50342f', '#222223'];

    // const colorRangeSeeds = ['#ffc41e', '#d95c55', '#222223'];

    seeds = seeds || [util.cssColor('range-start'), util.cssColor('range-end')];
    steps = steps || 7;

    return chroma.bezier(seeds).scale().correctLightness().colors(steps);
}

export function brandColorRange() {
    return colorRange([
        util.cssCustomProperty('brand-yellow'),
        util.cssCustomProperty('brand-orange'),
        '#dd5555',
        util.cssCustomProperty('brand-black')
    ]);
}

export function colorScale(domain, valueScaleKey) {
    // // This is the original red-blue color scale used in our bubble-diagrams in
    // // our tree-maps, bubble-diagrams, word-clouds, etc:

    // const range = [util.cssColor('range-start'), util.cssColor('range-end')];
    // const color = d3.scalePow(domain, range).exponent(0.4);

    // // This is a method for generating a d3.js color scale from a chroma.js palette
    // // suggested below the chroma.js palette helper: https://gka.github.io/palettes/
    // // However, this uses scaleThreshold which effectively limits you to having a
    // // linear scale unless you cleverly calculate the threshold values used..

    // const colorDomainMin = domain[0];
    // const colorDomainMax = domain[1];
    // const colorDomainInc = ((colorDomainMax - colorDomainMin) / colorRange.length);
    // const colorDomain = d3.range(1, colorRange.length).map(n => colorDomainMin + (colorDomainInc * n));
    // const colorScale = d3.scaleThreshold(colorDomain, colorRange);

    // // A shorthand way to achieve the same result, which has the same limitation,
    // // is to use scaleQuantize instead:

    // const colorScale = d3.scaleQuantize(domain, colorRange);

    // // ...but with this it's easier to see that we can use our preferred scale
    // // (i.e. scaleLinear, scaleLog, etc.) to map input values to a unit scale
    // // (i.e. 0 to 1 inclusive), and then map from there to discrete set of
    // // color values afterwards by using scaleQuantize, as shown below.

    // // Here are a variety of scale options to pick from:

    const valueScaleOptions = {
        'lin':      d3.scaleLinear(),
        'sqrt':     d3.scaleSqrt(),
        'pow-3rd':  d3.scalePow().exponent(0.33),
        'pow-4th':  d3.scalePow().exponent(0.25),
        'pow-10th': d3.scalePow().exponent(0.1),
        'log':      d3.scaleLog()
    };

    let valueScale = valueScaleOptions[valueScaleKey || 'pow-3rd'];
    let colorScale = d3.scaleQuantize();

    valueScale = valueScale.domain(domain).range([0, 1]);
    colorScale = colorScale.domain([0, 1]).range(brandColorRange());

    return n => colorScale(valueScale(n));
}
