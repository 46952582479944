export class TableOrderInPlace {
    constructor(elem) {
        this.$table = elem;
        this.$table.data('table-order-in-place', this);

        this.$thead = this.$table.find('> thead');
        this.$tbody = this.$table.find('> tbody');
        this.$th = this.$thead.find('> tr > th');

        // Add 'order' class and link to each <th>:
        this.$th.each(function(){
            var $cell = $(this);
            var $link = $('<a class="order" href="#"><span>' + $cell.html() + '</span></a>');
            if ($cell.hasClass('no-order-in-place')) return;
            $cell.html($link).addClass('order');
        });

        this.$order = this.$th.find('a.order');

        // Add click handler to each <th>:
        this.$th.click(e => {
            e.preventDefault();
            var idx = $(e.currentTarget).index();
            var dir = this.$order.eq(idx).hasClass('asc') ? -1 : 1;
            this.sort(idx, dir);
        });

        // Add an index to each <tr> in <tbody>, see
        // .currIndexOrder() and .sortIndexOrder():
        this.$tbody.find('> tr').each(function(index, elem){
            $(elem).data('original-index', index);
        });
    }

    rowValue(idx, row) {
        var $td = $(row.children[idx]);
        var valueData = $td.data('value');
        var valueText = $.trim($td.text());
        return valueData !== undefined ? valueData : valueText;
    }

    curr() {
        // Get the current sort ordering state as
        // params suitable for passing to .sort()
        var idx = this.$order.filter('.asc, .desc').closest('th').index();
        var dir = this.$order.eq(idx).hasClass('asc') ? 1 : -1;
        if (idx > -1) {
            return [idx, dir];
        } else {
            return [];
        }
    }

    sort(idx, dir) {
        if (typeof(idx) == 'undefined') return;
        if (typeof(dir) == 'undefined') dir = 1;

        var body = this.$tbody.get(0);
        var rows = this.$tbody.find('> tr').get();

        this.$order.removeClass('asc').removeClass('desc');
        this.$order.eq(idx).addClass((dir == -1) ? 'desc':'asc');

        rows.sort((a, b) => {
            a = this.rowValue(idx, a);
            b = this.rowValue(idx, b);
            if (typeof(a) == 'string') {
                return dir * a.localeCompare(b, 'en', {numeric: true, ignorePunctuation: true});
            } else {
                return dir * (a - b);
            }
        });

        for (var i = 0; i < rows.length; i++) {
            body.appendChild(rows[i]);
        }

        this.$table.trigger('order-in-place:changed');
    }

    currIndexOrder() {
        // In rare circumstances we have one set of columns split across two tables.
        // When sorting a column in one, we want to order the other's rows to match.
        // This is different to .curr() where we sort two tables by the same column.
        // See templates/statistics/geo-asn-index.html for an example.

        var rows = this.$tbody.find('tr').get();
        var curr = {};

        rows.forEach(function(tr, index){
            curr[$(tr).data('original-index')] = index;
        });

        return curr;
    }

    sortIndexOrder(lookup) {
        var body = this.$tbody.get(0);
        var rows = this.$tbody.find('tr').get();

        rows.sort(function(a, b){
            var a = lookup[$(a).data('original-index')];
            var b = lookup[$(b).data('original-index')];
            return a - b;
        });

        for (var i = 0; i < rows.length; i++) {
            body.appendChild(rows[i]);
        }
    }
}
