// 3rd party dependencies
import $ from 'jquery';
import Cookies from 'js-cookie';
import bb, {area, areaSpline, bar, line, pie, spline} from 'billboard.js';
import * as d3 from 'd3';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import 'jquery-ui/ui/widgets/menu';
import 'jquery-ui/ui/widgets/tooltip';
import 'tag-it';
import 'chosen-js';

// 1st party dependencies
import inited from './helpers/inited.js';
import util from './helpers/util.js';
import { initChosenSelect } from './helpers/chosen-select.js';
import { colorScale } from './helpers/color-scale.js';
import { Chart } from './helpers/chart.js';
import { hasTextOverflow } from './helpers/has-text-overflow.js';
import { TableOrderInPlace } from './helpers/table-order-in-place.js';
import './helpers/ajax-csrf-setup.js';


window.$ = window.jQuery = $;
window.d3 = d3;
window.bb = bb;
window.bbDataTypes = {area, 'area-spline': areaSpline, bar, pie, line, spline};
window.inited = inited;
window.util = util;
window.colorScale = colorScale;
window.Chart = Chart;
window.TableOrderInPlace = TableOrderInPlace;
const initGoogleAnalytics = window.initGoogleAnalytics;


$(function(){
    const app = window.app = new App();
});


class App {
    constructor() {
        this.$elem = $('body');

        this.initLoadHtml();
        this.initRequiredFieldsFix();
        this.initCancelButtons();

        // Links
        this.initLinks();
        this.initPostMethodLinks();
        this.initOpenPopupLinks();

        // Forms, formsets & fields
        this.initFields();

        // Graphs, charts & visualisations
        this.initResizeHandler();
        this.initCharts();

        // Feature-specific
        this.initTables();
        this.initCurrentHashLinks();
        this.initPerPage();
        this.initFocusFirstField();
        this.initTitleTooltips();
        this.initDisableButtonsAfterSubmit();
        this.initCredits();
        this.initGdpr();

        // Some initialisation can cause conflicts unless it occurs after
        // everything else is done. For instance if an element is hidden
        // its dimensions can't easily be measured later:

        this.$elem.trigger('app:inited');
    }

    getCurrentLanguageInfo() {
        return $('.lang-menu').data();
    }

    getTranslatedPath(path) {
        // Get path prefix for the currently selected language and add it
        // to the path we were given (assuming it's stripped/unprefixed).
        let prefix = this.getCurrentLanguageInfo().prefix;
        if (prefix) path = path.replace(/^\//, prefix);
        return path;
    }

    initPerPage() {
        $('#id_per_page').change(function (ev) {
            var url = new URL(window.location);
            url.searchParams.set('per_page', $(this).val());
            window.location = url.toString();
        });
    }

    initCurrentHashLinks() {
        // Automatically updates links to include current location hash.
        function updateLinks() {
            // replace hash in links with current-hash-link class
            $('a.current-hash-link').each(function () {
                var href = $(this).attr('href');
                var parts = href.split('#');
                var base = parts.length > 1 ? parts.slice(0,-1).join('#'): href;
                $(this).attr('href', base + window.location.hash);
            });
        }
        $(window).bind('hashchange', updateLinks);
        updateLinks();
    }

    isPageClass() {
        for (let cls of this.$elem.prop('classList')) {
            for (let arg of arguments) {
                if (typeof arg == 'string') {
                    if (cls == arg) {
                        return true;
                    }
                } else {
                    if (arg.test(cls)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    inPageClass() {
        if (arguments.length) {
            var options = Array.prototype.slice.call(arguments);
            var pattern = new RegExp('\\b(' + options.join('|') + ')\\b');
            var classes = this.$elem.prop('className');
            if (pattern.test(classes)) {
                return true;
            }
        }
        return false;
    }

    message(message, tag, id) {
        var $list = this.$elem.find('.page-messages');
        if ($list.find('.message-id-' + id).length) return;

        if ($list.length == 0) {
            $list = $('<div class="page-messages">');
            $list.prependTo('main .container');
        }

        var $item = $('<div class="message message-fade">' + message + '</div>');

        if (tag) {
            $item.addClass('message-' + tag);
        }

        if (id) {
            $item.addClass('message-id-' + id);
        }

        $list.append($item);
    }

    messageRemove(id) {
        var $list = this.$elem.find('.page-messages');
        var $item = $list.find('.message-id-' + id);
        $item.remove();
    }

    messagesCreate(messages) {
        for (var i=0; i<messages.length; i++) {
            var m = messages[i];
            this.message(m.message, m.tag);
        }
    }

    messagesRemove() {
        this.$elem.find('.page-messages').remove();
    }

    fieldErrors($fieldOrForm, messages, messageClass) {
        if (typeof messages == 'string') {
            messages = [messages];
        }

        var isForm = $fieldOrForm.is('form');
        var $parent = isForm ? $fieldOrForm : $fieldOrForm.closest('div.field');
        var $errors = $parent.find('> .errorlist');

        if (!messages || messages.length == 0) {
            $errors.remove(); return;
        } else if ($errors.length) {
            $errors.empty();
        } else {
            $errors = $('<ul class="errorlist">');
            $errors[isForm ? 'prependTo' : 'appendTo']($parent);
        }

        for (const message of messages) {
            let $message = $('<li>').html(message);
            if (messageClass) $message.addClass(messageClass);
            $errors.append($message);
        }
    }

    initLoadHtml() {
        var init = () => {
            this.$elem.find('.load-html').each((i, elem) => {
                var $elem = $(elem);
                if ($elem.is(':visible') == false) return;

                if (inited($elem, 'load-html')) return;

                var path = $elem.data('load-source');
                if (path) {
                    $elem.html('<div class="loading-icon"></div>');
                    $elem.load(path, (response, status, xhr) => {
                        if (status == 'error') {
                            $elem.html('<div class="empty-message empty-message-compact">Failed to load content.</div>');
                            $elem.addClass('load-html-done');
                        } else {
                            this.initFields();
                            this.initTables();
                            this.changed();
                            $elem.addClass('load-html-done');
                        }
                    });
                }
            });
        };

        this.$elem.on('app:inited', init);
        this.$elem.on('app:changed', init);
    }

    initRequiredFieldsFix() {
        // Django 1.10 started adding a required attribute to fields whose
        // widget has required=True, but if any required fields are hidden
        // when the browser attempts client-side HTML5 validation:
        //
        //  - Chrome will throw a JS error and prevent form submission.
        //  - Firefox will show the validation message at the top-left of
        //    the page with no indication of the originating field.
        //
        // As a workaround we can just set the required property to false
        // for any hidden fields since the client-side validation will be
        // backed up by server-side validation anyway.

        var self = this;

        function requiredFieldsFix() {
            self.$elem.find('[required]:hidden').each(function(){
                if (inited(this, 'required-fields-fix')) return;
                $(this).prop('required', false);
            });
        }

        this.$elem.on('app:inited', requiredFieldsFix);
        this.$elem.on('app:changed', requiredFieldsFix);
    }

    initLinks() {
        var self = this;

        this.$elem.on('click', 'a', function(e) {
            var link = $(this);
            if (link.attr('href') === '#'
            ||  link.hasClass('inactive')
            ||  link.closest('.inactive').length) {
                e.preventDefault();
            } else {
                // Remove referrer for external links
                if (this.hostname && this.hostname !== window.location.hostname) {
                    link.attr('rel', 'noreferrer');
                }
            }
        });
    }

    initTableColumnOrdering() {
        // Make the table header ordering links behave as though
        // the whole cell is clickable, rather than just the link.
        $('table.table').each(function(){
            if (inited(this, 'table-column-ordering')) return;
            $(this).find('th a.order').each(function(){
                var link = $(this);
                var cell = link.closest('th');

                cell.addClass('order');
                cell.click(function(e){
                    location.href = link.attr('href');
                    e.preventDefault();
                });
            });
        });
    }

    initTableColumnOrderingInPlace() {
        $('table.table-order-in-place').each(function(){
            if (inited(this, 'table-column-ordering-in-place')) return;
            new TableOrderInPlace($(this));
        });
    }

    initTableTruncateTitles() {
        let selector = 'th.truncate a.order > div, td.truncate';

        $('table.table')
            .on('mouseenter', selector, function(){
                if (this.classList.contains('title-tooltip')) return;
                if (hasTextOverflow(this)) {
                    this.dataset.originalTitle = this.title;
                    this.title = this.innerText;
                }
            })
            .on('mouseleave', selector, function(){
                if (!('originalTitle' in this.dataset)) return;
                if (this.dataset.originalTitle) {
                    this.title = this.dataset.originalTitle;
                } else {
                    this.removeAttribute('title');
                }
                delete this.dataset.originalTitle;
            });
    }

    initTableOtherActions() {
        // When hovering an other-actions toggle, temporarily
        // disable overflow:hidden on the parent table cell
        // so that the menu options are visible (the overflow
        // functionality is necessary for TableColumnWidths).
        $('table.table').each(function(){
            if (inited(this, 'table-other-actions')) return;
            var $table = $(this);
            var $other = $table.find('.other-actions');
            $other.hover(function(){
                $(this).closest('td').css('overflow', 'visible');
            }, function(){
                $(this).closest('td').css('overflow', 'hidden');
            });
        });
    }

    initPostMethodLinks() {
        this.$elem.on('click', 'a.post-link, a.confirm, a.delete', function(e){
            e.preventDefault();

            var $link = $(this);
            var $form = $('<form method="post" action="' + $link.attr('href') + '">');

            var isDelete = $link.hasClass('delete');
            var isConfirm = $link.hasClass('confirm');
            var deleteDescription = isDelete && $link.data('delete-description');
            var confirmMessage = isConfirm && $link.data('confirm-message');
            var postData = $link.data('post-data');

            if (isDelete || isConfirm) {
                var message = "Are you sure?";

                if (deleteDescription) {
                    message = "You're about to delete the following item:\n\n"
                            + '"' + deleteDescription + '"\n\n'
                            + "Are you sure you want to continue?";
                }

                if (confirmMessage) {
                    message = confirmMessage;
                }

                if (confirm(message) === false) {
                    return;
                }
            }

            $link.after($form);
            $form.append('<input type="hidden" name="csrfmiddlewaretoken" value="' + Cookies.get('csrftoken') + '">');

            if (postData) {
                for (const [key, val] of Object.entries(postData)) {
                    $form.append('<input type="hidden" name="' + key + '" value="' + val + '">');
                }
            }

            $form.submit();
        });
    }

    initOpenPopupLinks() {
        this.$elem.on('click', 'a.open-popup', function(e){
            var $link = $(this);

            var url = $link.attr('href');
            if (url == '' || url == '#') { e.preventDefault(); return; }

            // We need some way of notifying an element in the parent window when
            // the relevant popup window is closed (cf. the 'popup:closed' event).
            // In lieu of a more convenient method, we're passing an element ID as
            // part of the new window's name.  We can manually specify this ID via
            // a data attribute (see below), or we can look for an ID on the
            // triggering link. But if no other ID is found we can just generate
            // something based on the link's URL instead:

            var targetId = $link.data('popup-target') || $link.attr('id');
            if (targetId == null) {
                targetId = url.replace(/\W+/g, '_').replace(/^_|_$/g, '');
                $link.attr('id', targetId);
            }

            // If we're in a create form for a parent-child relationship, i.e. where
            // ForeignKey('self'), we need to avoid reusing identical window names
            // for nested popups otherwise the child will overwrite its parent:

            var win = window;
            var winDepth = 1;
            while (win = win.opener) { winDepth++; }

            var str = 'popup_target_' + targetId + '_depth' + winDepth;
            var opt = 'height=500,width=850,resizable=yes,scrollbars=yes';

            url += ((url.indexOf('?') > -1) ? '&':'?') + '_popup=1';

            var win = window.open(url, str, opt);
            if (win) e.preventDefault();
        });
    }

    initCharts() {
        var debug = !!+(new URL(location)).searchParams.get('debug');
        if (debug) this.charts = [];

        var init = () => {
            this.$elem.find('.chart').not('.chart-manual').each((i, elem) => {
                // The chart won't render correctly in firefox until the element
                // is visible.  Note that init() is re-run whenever app.changed()
                // is called (for example when switching between tabs).
                var $elem = $(elem);
                if ($elem.is(':visible') === false) return;
                if (inited(elem, 'chart')) return;
                var chart = new Chart(this, $elem);
                if (debug) this.charts.push(chart);
            });
        };

        this.$elem.on('app:inited', init);
        this.$elem.on('app:changed', init);
    }

    initFields() {
        // Methods called from here must be runnable multiple times post
        // page-load without ill effects (i.e. use the inited() function).

        this.initHelpText();
        this.initDatePickers();
        this.initChosenSelects();
    }

    initTables() {
        // Methods called from here must be runnable multiple times post
        // page-load without ill effects (i.e. use the inited() function).

        this.initTableColumnOrdering();
        this.initTableColumnOrderingInPlace();
        this.initTableTruncateTitles();
        this.initTableOtherActions();
    }

    initFocusFirstField() {
        // Don't steal focus if a field is already focused.
        if ($(':focus').length) return;

        // Some pages should not have their forms autofocused (e.g. index pages).
        if (!this.inPageClass('create', 'update', 'filter') || this.isPageClass('no-focus')) return;

        var $field = $('.stats-content form :input:visible').not(':button, :submit').first();
        var $outer = $field.closest('.field');

        // Focus the first field.
        $field.focus();

        // Hide datepicker if necessary (see .initDatePickers()).
        if ($field.is('.date-picker-field')) {
            $field.datepicker('hide');
        }

        // Hide help-text tooltip if necessary (see .initHelpText()).
        if ($outer.tooltip('instance')) {
            $outer.tooltip('close');
        }
    }

    initTitleTooltips() {
        // Note that there is another tooltip initialiser.
        // See: App.initHelpText()

        var self = this;

        function titleTooltips() {
            self.$elem.find('.title-tooltip[title]').each(function() {
                if (inited(this, 'title-tooltip')) return;

                var $elem = $(this);
                var using = function(position, feedback) {
                    // Set the tooltip's position and move the arrowhead
                    // to the correct side to point at the target.
                    $(this).css(position).addClass(feedback.vertical);

                    // Try to make sure that the tooltip's arrowhead always
                    // points at the target element's center, even if the
                    // tooltip has been shifted to fit within the viewport.
                    var x1 = feedback.element.left;
                    var x2 = feedback.target.left;
                    var x = (x2 - x1) + (feedback.target.width / 2);
                    this.style.setProperty('--after-left', x + 'px');
                }

                var config = {
                    show: false,
                    hide: false,
                    content: () => $elem.prop('title'),
                    position: {
                        my: 'center bottom',
                        at: 'center top-15',
                        collision: 'flipfit',
                        using: using
                    }
                };

                var classes = $elem.data('tooltip-classes');
                if (classes) {
                    config.classes = {'ui-tooltip': classes};
                }

                $elem.tooltip(config);
            });
        }

        this.$elem.on('app:inited', titleTooltips);
        this.$elem.on('app:changed', titleTooltips);
    }

    initHelpText() {
        var self = this;
        var titlesSelector = '.form-col.field[title], .table-formset:not(.no-help-text) .field[title]'
        var $fieldTitles = this.$elem.find(titlesSelector);

        $fieldTitles.each(function(){
            if (inited(this, 'help-text')) return;

            var elem = $(this);

            var content = function() {
                // Circumvent jQuery UI's escaping of HTML in
                // title since we're in control of the content:
                return $(this).prop('title');
            };

            var usingV = function(position, feedback) {
                $(this).css(position).addClass(feedback.vertical);
            };
            var usingH = function(position, feedback) {
                $(this).css(position).addClass(feedback.horizontal);
            };

            if (elem.hasClass('field-full-width') || window.innerWidth <= 1200) {
                // For full width fields on large screens and all fields on
                // smaller screens, place the tooltip underneath so it does
                // not obscure the input.
                var position = { using: usingV, my: 'right top', at: 'right bottom+15' };
            } else if (elem.closest('.table-formset').length || self.inPageClass('popup')) {
                var position = { using: usingV, my: 'center bottom', at: 'center-8 top-15' };
            } else {
                var position = { using: usingH, my: 'left top', at: 'right+12 top-3' };
            }

            elem.tooltip({
                content: content,
                position: position,
                show: false,
                hide: false,
                // As per the documentation from
                // https://api.jqueryui.com/tooltip/#option-content as
                // we are using the content option we also specify
                // items to restrict the elements that trigger the
                // tooltip.
                items: titlesSelector,
                open: function(event, ui) {
                    // Try to make sure we don't see :hover and :focus tooltips
                    // for two different fields at the same time, for example
                    // if the mouse is over one field while tabbing through the
                    // other fields:

                    $fieldTitles.not(event.target).each(function(){
                        if ($(this).tooltip('instance')) {
                            $(this).tooltip('close');
                        }
                    });
                }
            });
        });
    }

    /**
     * When the Cancel button is clicked on a form, set novalidate on the form so that it can be "submitted"
     * to trigger the cancel operation without any HTML5 validation getting in the way.
     */
    initCancelButtons() {
        $('button[name="action"][value="cancel"]').on('click', function(e) {
            if (e.originalEvent) {
                var $this = $(this);
                e.preventDefault();
                $this.closest('form').attr('novalidate', true);
                $this.trigger('click');
            }
        });
    }

    initDatePickers() {
        let $datePickers = this.$elem.find('.date-picker-field');
        if ($datePickers.length == 0) return;

        let locale = this.getCurrentLanguageInfo().locale;
        if (locale && locale != 'en' && !this.initDatePickersLocaleLoaded) {
            this.initDatePickersLocaleLoaded = true;

            // Account for some differences in the way our locales
            // are named versus the locales used by the datepicker.
            locale = locale.replace('_', '-');
            locale = {'zh-Hans':'zh-CN', 'zh-Hant':'zh-TW'}[locale] ?? locale;

            // This wont finish loading before our date pickers are initialised but
            // that's okay because it should finish before the user has a chance to
            // click a date field and they'll still ultimately see translated text.
            $.getScript(`/static/js/jquery-ui/ui/i18n/datepicker-${locale}.js`);

        }

        $datePickers.each(function(){
            if (inited(this, 'date-picker')) return;

            let $field = $(this);

            // See the documentation for possible formatting chars:
            // http://api.jqueryui.com/datepicker/#utility-formatDate
            let format = $field.data('date-picker-format');
            if (format === undefined) {
                format = 'yy-mm-dd';
            }
            let mindate = $field.data('mindate');
            if (mindate === undefined) {
                mindate = null;
            }
            let maxdate = $field.data('maxdate');
            if (maxdate === undefined) {
                maxdate = null;
            }

            let config = {
                'dateFormat': format,
                'minDate': mindate,
                'maxDate': maxdate,
                'showAnim': ''
            };

            if ($field.css('direction') == 'rtl') {
                config.isRTL = true;
            }

            $(this).datepicker(config);
        });
    }

    initChosenSelects() {
        var self = this;

        this.$elem.find('select.chosen').each(function(){
            initChosenSelect($(this));
        });
    }

    initResizeHandler() {
        $(window).resize(() => {
            this.resized();
        });
    }

    whenFinished(name, callback) {
        // Multiple resize or change events are sometimes triggered
        // in quick succession as a consequence of scripting or user
        // actions (e.g. window resizing). The operations that might
        // be carried out by listeners for these events can be very
        // expensive, so we should only handle them after the series
        // of events has paused or ended.

        var timeoutIdName = name + 'TimeoutId';

        if (this[timeoutIdName]) {
            clearTimeout(this[timeoutIdName]);
            this[timeoutIdName] = null;
        }

        this[timeoutIdName] = setTimeout(callback, 100);
    }

    resized() {
        // The window was resized.
        this.whenFinished('resized', function(){
            this.$elem.trigger('app:resized');
        }.bind(this));
    }

    changed() {
        // Content was loaded or toggled.
        this.whenFinished('changed', function(){
            this.$elem.trigger('app:changed');
        }.bind(this));
    }

    initDisableButtonsAfterSubmit() {
        // Unless the button has an attribute autocomplete="off" at
        // page load, Firefox will cache a dynamically set 'disabled'
        // property between page refreshes. So, unless the btn has a
        // disabled class, we're going to assume it should be enabled.
        $(':submit.btn').not('.btn-disabled').each(function () {
            this.disabled = false;
        });
        $(document).on('submit', function (ev) {
            // If the form has a target set (e.g. it's posting to '_blank')
            // don't disable the button, since we won't be navigating away
            // and it will stay disabled even after the submit finishes.
            var $form = $(ev.target);
            if ($form.prop('target')) return;

            // Do this asynchronously so the 'disabled' attribute
            // doesn't prevent the browser sending the button
            // 'value' to the server
            setTimeout(function () {
                $(':submit.btn', ev.target).each(function () {
                    this.disabled = true;
                    $(this).addClass('btn-disabled');
                });
            }, 0);
        });
    }

    initCredits() {
        const $link = $('.smallprint .credits a');

        $link.click(e => {
            e.preventDefault();

            let $dialog = $('<div>');
            let content = $('#credits').html();

            $dialog.append(content);
            $dialog.dialog(util.dialogConfig({
                title: gettext("Credits"),
                buttons: [
                    {
                        text: gettext("OK"),
                        click: () => $dialog.dialog('close')
                    }
                ]
            }));
        });
    }

    initGdpr() {
        var $gdpr = $('.gdpr-message');

        var previouslyAccepted = localStorage.getItem('shadowserver--gdpr--acted');
        if (previouslyAccepted === null) {
            $gdpr.show();
        }

        $gdpr.find('.gdpr-message-actions button').on('click', function() {
            var $this = $(this);
            var value = $this.hasClass('accept') ? 1 : 0;
            localStorage.setItem('shadowserver--gdpr--acted', value);
            $gdpr.hide();

            if (value) {
                initGoogleAnalytics();
            }
        });
    }
}
