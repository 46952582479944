import $ from 'jquery';
import Cookies from 'js-cookie';

// Init CSRF protection before loading any other modules
// See: https://docs.djangoproject.com/en/3.0/ref/csrf/#setting-the-token-on-the-ajax-request
$.ajaxSetup({
    beforeSend: function(xhr, settings) {
        var CSRFToken = Cookies.get('csrftoken');

        // Some HTTP methods don't require CSRF protection:
        var methodNeedsToken = !(/^(GET|HEAD|OPTIONS|TRACE)$/.test(settings.type));
        // Prevent our token from being sent to other domains:
        var domainNeedsToken = !this.crossDomain;

        if (methodNeedsToken && domainNeedsToken) {
            xhr.setRequestHeader('X-CSRFToken', CSRFToken);
        }
    }
});
