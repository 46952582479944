import Cookies from 'js-cookie';
import * as html2canvas from 'html2canvas';

const util = {
    csrfToken: function() {
        // This mainly exists because some of our templates have inline JS
        // which needs it, and they don't have the ability to import npm
        // modules to get access to `Cookies`. Eventually that inline JS
        // should be made into standalone scripts which can do imports.
        return Cookies.get('csrftoken');
    },

    pad: function(s, w, c) {
        s = '' + s;
        c = '' + (c || 0);
        while (s.length < w) s = c + s;
        return s;
    },

    prefix: function() {
        // See: https://davidwalsh.name/vendor-prefix
        var prefix = '';
        var styles = window.getComputedStyle(document.documentElement, '');
        var joined = Array.prototype.slice.call(styles).join(' ');

        var match = joined.match(/-(moz|webkit|ms)-/);
        if (match) {
            prefix = match[1];
        } else if (styles.OLink === '') {
            prefix = 'o';
        }

        return prefix;
    },

    cssCustomProperty: function(name, computedStyle) {
        var computedStyle = computedStyle || getComputedStyle(document.body);
        var propertyValue = computedStyle.getPropertyValue('--' + name);
        // The property value is returned with a leading space character in some
        // browsers unless the value is defined as `--foo:bar;` even though the
        // spec seems to say `--foo: bar;` is valid.
        return propertyValue.trim();
    },

    cssColor: function(name, computedStyle) {
        return this.cssCustomProperty('color-' + name, computedStyle);
    },

    cssShade: function(name, computedStyle) {
        return this.cssCustomProperty('shade-' + name, computedStyle);
    },

    prefixed: function(s) {
        var p = this.prefix();
        if (p) {
            s = '-' + p + '-' + s;
        }
        return s;
    },

    objectsAreEqual: function(a, b) {
        var aJson = JSON.stringify(a);
        var bJson = JSON.stringify(b);
        return aJson == bJson;
    },

    hash: function(str) {
        // From https://github.com/darkskyapp/string-hash
        var hash = 5381;
        var i = str.length;

        while(i) {
            hash = (hash * 33) ^ str.charCodeAt(--i);
        }

        return hash >>> 0;
    },

    regexp: {
        // See: https://stackoverflow.com/a/3561711
        escapeRE: /[-\/\\^$*+?.()|[\]{}]/g,
        escape: function(s) {
            return s.replace(this.escapeRE, '\\$&');
        }
    },

    number: {
        commas: function(n) {
            return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        sigFigs: function(n, sigfigs) {
            var figs = Math.ceil(Math.log10(n));
            var tens = Math.pow(10, figs - sigfigs);
            return Math.round(n / tens) * tens;
        },

        ip2int: function(ip) {
            var octets = ip.split('.');
            var a = parseInt(octets[0], 10) * 256 * 256 * 256,
                b = parseInt(octets[1], 10) * 256 * 256,
                c = parseInt(octets[2], 10) * 256,
                d = parseInt(octets[3], 10);
            return a + b + c + d;
        },

        round: function(value, places) {
            if (typeof places === 'undefined') places = 1;
            var tens = Math.pow(10, places);
            return (Math.round(value * tens) / tens).toFixed(places);
        },

        units: function(value, places, opts) {
            if (typeof opts == 'undefined') opts = {};
            if (typeof opts.scale == 'undefined') opts.scale = 1000;
            if (typeof opts.fixed == 'undefined') opts.fixed = true;
            if (typeof opts.space == 'undefined') opts.space = '';
            if (typeof opts.units == 'undefined') opts.units = ['', 'K', 'M', 'B', 'T'];
            if (value != null) {
                for (var i = 0; i < opts.units.length; i++) {
                    if (Math.abs(value) < opts.scale || i == (opts.units.length - 1)) {
                        value = util.number.round(value, places);
                        value = (opts.fixed) ? value : value.replace(/\.0+$/, '');
                        value = value + opts.space + opts.units[i];
                        return value;
                    }
                    value /= opts.scale;
                }
            }
        }
    },

    defaults: function(options, defaultValues) {
        // Given some options as passed to the parent function, and
        // some defaults, apply those defaults if nothing was given
        // for them.
        options = options || {};
        $.each(defaultValues, function(key, value) {
            if (!options.hasOwnProperty(key)) {
                options[key] = value;
            }
        });
        return options;
    },

    download: function(filename, callback) {
        // If we were passed a data URI instead of a callback function
        // then there isn't any rendering to do. Just pass the value on:

        if (typeof(callback) === 'string') {
            let filedata = callback;
            callback = (fn) => fn(filedata);
        }

        callback(function(href){
            let a = document.createElement('a');
            a.href = href;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    },

    downloadAsPng: function(node, filename, callback, scaleToFit = true, watermarkAbove = false, contentPadding = 0) {
        var opts = {'bgcolor': 'white'};

        if (node.tagName == 'svg' && scaleToFit) {
            // For SVGs we scale the rendering up to fill
            // the available screen space and to match
            // the device's pixel ratio (i.e. dimensions
            // will be doubled for most Apple devices).

            const bounds = node.getBoundingClientRect();

            const boundsW = bounds.width;
            const boundsH = bounds.height;

            const screenW = screen.availWidth;
            const screenH = screen.availHeight;

            const scale = window.devicePixelRatio;
            const ratio = boundsW / boundsH;

            let w = boundsW;
            let h = boundsH;

            if (boundsW < screenW && boundsH < screenH) {
                if (ratio > 1) {
                    w = screenW;
                    h = screenW / ratio;
                } else {
                    w = screenH * ratio;
                    h = screenH;
                }
            }

            opts.width  = scale * w;
            opts.height = scale * h;
        }

        this.renderToCanvas(node).then(canvas => {
            canvas = this.cropWhitespace(canvas);
            canvas = this.addPadding(canvas, contentPadding);
            this.addWatermark(canvas, watermarkAbove, canvas => {
                this.download(filename, canvas.toDataURL());
                callback();
            }, contentPadding);
        }).catch(err => console.error(err));
    },

    renderToCanvas: function(node, opts) {
        return html2canvas(node, Object.assign({
            'logging': false,
            'ignoreElements': el => {
                return el.classList.contains('no-render')
            }
        }, opts));
    },

    xhr: {
        errorMessage: function(xhr, defaultMsg) {
            let msg = defaultMsg || "Unable to load content.";
            let err = (xhr.responseJSON && xhr.responseJSON.error) || xhr.statusText;
            if (err) msg = msg.replace(/\.$/, ':') + ' "' + err + '"';
            return msg;
        }
    },

    addPadding: function (original, padding) {
        if (!padding) {
            return original;
        }
        const canvas = document.createElement('canvas');
        canvas.width = original.width + padding * 2;
        canvas.height = original.height + padding * 2
        const ctx = canvas.getContext('2d', { alpha: false });
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(original, padding, padding);
        return canvas;
    },

    // Setting the watermarkAbove option to true will place the watermark
    // above the image rather than overlaying it in the top right corner.
    addWatermark: function (original, watermarkAbove, callback, contentPadding = 0) {
        const watermark = new Image();
        watermark.onload = () => {
            // watermark source/dest position and scale
            const sx = 0;
            const sy = 0;
            const sw = watermark.naturalWidth;
            const sh = watermark.naturalHeight;
            const dx = 0;
            const dy = 0;
            var dw = original.width / 5;
            var dh = dw * (sh / sw);

            // If exporting a narrow width image the watermark can be scaled too small,
            // so take up increasing % of image width in this case.
            if (dh < 58) {
                dw = original.width / 3;
                dh = dw * (sh / sw);
            }
            if (dh < 58) {
                dw = original.width / 2;
                dh = dw * (sh / sw);
            }
            if (dh < 58) {
                dw = original.width;
                dh = dw * (sh / sw);
            }

            const canvas = document.createElement('canvas');
            canvas.width = original.width;
            canvas.height = (
                original.height + 30 +
                (watermarkAbove ? (dh - contentPadding) : 0)
            );

            const ctx = canvas.getContext('2d', { alpha: false });

            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(original, 0, watermarkAbove ? (dh - contentPadding) : 0);
            ctx.globalAlpha = 0.2;
            ctx.globalCompositeOperation = 'darken';
            ctx.drawImage(watermark, sx, sy, sw, sh, dx, dy, dw, dh);
            ctx.globalAlpha = 1;

            ctx.font = '12px Fira Sans';
            const year = new Date().getFullYear();
            const copyright = `© ${year} The Shadowserver Foundation`;
            const txt = ctx.measureText(copyright);
            ctx.fillStyle = 'black';
            ctx.fillText(copyright, canvas.width - 10 - txt.width, canvas.height - 10);

            callback(canvas);
        };
        watermark.src = "/static/img/statistics/shadowserver-watermark-padded.svg";
    },

    // returns x, y, w, h for rectangle within image that contains non-white pixels
    cropWhitespaceDimensions: function (ctx) {
        const width = ctx.canvas.width;
        const height = ctx.canvas.height;
        const data = ctx.getImageData(0, 0, width, height);
        const isWhite = (pixel) => {
            return pixel[0] === 255 && pixel[1] === 255 && pixel[2] === 255;
        };
        const getPixel = (x, y) => {
            return ctx.getImageData(x, y, 1, 1).data;
        };
        // find left-most non-white pixel
        var left = 0;
        for (var y = 0; y < height; y++) {
            for (var x = 0; x < (y === 0 ? width : left); x++) {
                if (!isWhite(getPixel(x, y))) break;
            }
            left = y === 0 ? x : Math.min(left, x);
        }
        // find top-most non-white pixel
        var top = 0;
        for (var x = 0; x < width; x++) {
            for (var y = 0; y < (x === 0 ? height: top); y++) {
                if (!isWhite(getPixel(x, y))) break;
            }
            top = x === 0 ? y : Math.min(top, y);
        }
        // find right-most non-white pixel
        var right = width;
        for (var y = 0; y < height; y++) {
            for (var x = width - 1; x >= (y == 0 ? left : right); x--) {
                if (!isWhite(getPixel(x, y))) break;
            }
            right = y === 0 ? x : Math.max(right, x);
        }
        // find bottom-most non-white pixel
        var bottom = height;
        for (var x = 0; x < width; x++) {
            for (var y = height - 1; y >= (x === 0 ? top : bottom); y--) {
                if (!isWhite(getPixel(x, y))) break;
            }
            bottom = x === 0 ? y : Math.max(bottom, y);
        }
        return [left, top, right - left + 1, bottom - top + 1];
    },

    cropWhitespace: function (original) {
        const ctx = original.getContext('2d', { alpha: false });
        const [sx, sy, w, h] = this.cropWhitespaceDimensions(ctx);
        const canvas = document.createElement('canvas');
        canvas.width = w;
        canvas.height = h;
        const ctx2 = canvas.getContext('2d', { alpha: false });
        ctx2.fillStyle = 'white';
        ctx2.fillRect(0, 0, w, h);
        ctx2.drawImage(original, sx, sy, w, h, 0, 0, w, h);
        return canvas;
    },

    loadingIndicatorBtn: function (txt) {
        const $a = $('<a class="btn btn-tiny btn-full-width btn-loading btn-loading-indicator" href="#"></a>');
        const $icon = $('<div class="loading-icon"></div>');
        const $text = $('<div class="loading-text"></div>').text(txt);
        return $a.append($icon).append($text);
    },

    static: {
        getPathFromManifest: function(path) {
            if (this.hasOwnProperty('manifest') === false) {
                try {
                    this.manifest = JSON.parse($('#staticfiles-manifest').text());
                } catch (error) {
                    this.manifest = null;
                }
            }

            if (this.manifest) {
                path = path.replace(/^\/static\//, '');
                path = this.manifest[path] || path;
                path = '/static/' + path;
            }

            return path;
        }
    },

    handleResize: function(element, callback) {
        // We used to listen for resize events on the window or body
        // when updating/redrawing elements, but that has the major
        // disadvantage that the event handlers will still be called
        // even if the element has been removed from the DOM. Using a
        // ResizeObserver on the element itself should prevent that.

        const $elem = $(element);
        // const label = $elem.prop('className');
        // const debug = (message) => console.log(`${label}: ${message}`);
        const debug = () => {}

        let prevW = $elem.width();
        let prevH = $elem.height();
        let timeoutId = null;

        const observer = new ResizeObserver(() => {
            // Wait until there's a pause in resizing before
            // doing potentially expensive DOM manipulation.

            if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = null;
            }

            timeoutId = setTimeout(finished, 100);
        });

        const finished = () => {
            let currW = $elem.width();
            let currH = $elem.height();

            debug('resize: checking element...');

            if (currW == 0 && currH == 0) {
                // Element was removed from DOM after setTimeout was called.
                debug('resize: element was removed');
            } else if (currW == prevW && currH == prevH) {
                // Element size hasn't change.
                debug('resize: element is unchanged');
            } else {
                // Element size has changed.
                debug(`resize: element changed (${prevW}, ${prevH} > ${currW}, ${currH})`);

                prevH = currH;
                prevW = currW;

                callback();
            }
        };

        observer.observe(element);
    },

    dialogConfig: function(config) {
        // Note that the different properties used for width and height here
        // are because we typically want a fixed width but a variable height.

        let configW = config.width || 500;
        let configMaxH = config.maxHeight || 600;

        let getMaxW = () => {
            const windowW = $(window).width();
            const padding = (windowW < 600) ? 15 : 30;
            return Math.min(configW, windowW - (padding * 2));
        };

        let getMaxH = () => {
            const windowW = $(window).width();
            const windowH = $(window).height();
            const padding = (windowW < 600) ? 15 : 30;
            return Math.min(configMaxH, windowH - (padding * 2));
        };

        delete config.width;
        delete config.maxHeight;

        const openHandler = (event) => {
            // Close the dialog when we click outside it.
            const $dialog = $(event.target);
            const instance = $dialog.dialog('instance');
            if (instance && instance.overlay) {
                instance.overlay.click(() => {
                    $dialog.dialog('close');
                });
            }
        };

        const closeHandler = (event) => {
            // Remove the dialog's DOM nodes on close.
            const $dialog = $(event.target);
            $dialog.dialog('destroy');
        };

        let defaultConfig = {
            width: getMaxW(),
            minHeight: 0,
            maxHeight: getMaxH(),
            modal: true,
            autoOpen: true,
            closeOnEscape: true,
            draggable: false,
            resizable: false,
            open: openHandler,
            close: closeHandler
        };

        return Object.assign(defaultConfig, config);
    }
};

export default util;
