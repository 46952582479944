// Helper functions to paper over differences when accessing
// data attributes for jQuery result sets or "raw" DOM nodes.
//
// Note that DOM nodes also have a `node.dataset` property but
// we can't use it because it doesn't support hyphenated names.

function getDataAttribute(node, name) {
    if (node.jquery) {
        return node.data(name);
    } else {
        return node.getAttribute('data-' + name);
    }
}

function setDataAttribute(node, name, value) {
    if (node.jquery) {
        node.data(name, value);
    } else {
        node.setAttribute('data-' + name, value);
    }
}

// Guard against possible re-initialisation of elements.
// Particularly when calling app.initFields() after new
// elements have been dynamically added to a form.

export default function inited(node, feature) {
    let name = 'inited-' + feature;
    if (getDataAttribute(node, name)) {
        return true;
    } else {
        setDataAttribute(node, name, true);
        return false;
    }
};
